<template>
  <div class="main">
    <div class="main__background" id="top">
        <img class="main__background--img" src="../../assets/textures/rosa.png" alt="paint" />
    </div>
    <div class="main__header">
      <h2 class="main__header--title">MIS PINTURAS</h2>
    </div>
    <div class="main__gallery">
      <div class="gallery__content">
        <img class="gallery__content--img" v-for="(image, i) in pageOfItems" :src="image" :key="i" @click="index = i">
      </div>
    </div>
    <div class="main__pagination">
      <a href="#top">
        <jw-pagination :items="images" @changePage="onChangePage"></jw-pagination>
      </a>
    </div>
    <vue-gallery-slideshow :images="pageOfItems" :index="index" @close="index = null"></vue-gallery-slideshow>
    <div class="main__footer">
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import VueGallerySlideshow from 'vue-gallery-slideshow';
import Footer from '../../components/Footer.vue';

/**IMAGENES */
import P2 from '../../assets/images/Pinturas/2.jpg';
import P1 from '../../assets/images/Pinturas/1.jpg';
import P3 from '../../assets/images/Pinturas/3.jpg';
import P4 from '../../assets/images/Pinturas/4.jpg';
import P5 from '../../assets/images/Pinturas/5.jpg';
import P6 from '../../assets/images/Pinturas/6.jpg';
import P7 from '../../assets/images/Pinturas/7.png';
import P8 from '../../assets/images/Pinturas/8.png';
import P9 from '../../assets/images/Pinturas/9.png';
import P10 from '../../assets/images/Pinturas/10.png';
import P11 from '../../assets/images/Pinturas/11.png';
import P12 from '../../assets/images/Pinturas/12.png';
import P13 from '../../assets/images/Pinturas/13.jpg';
import P14 from '../../assets/images/Pinturas/14.jpg';
import P15 from '../../assets/images/Pinturas/15.jpg';
import P16 from '../../assets/images/Pinturas/16.jpg';
import P17 from '../../assets/images/Pinturas/17.jpg';
import P18 from '../../assets/images/Pinturas/18.jpg';
import P19 from '../../assets/images/Pinturas/19.jpg';
import P20 from '../../assets/images/Pinturas/20.jpg';
import P21 from '../../assets/images/Pinturas/21.jpg';
import P22 from '../../assets/images/Pinturas/22.jpg';
import P23 from '../../assets/images/Pinturas/23.jpg';
import P24 from '../../assets/images/Pinturas/24.jpg';
import P25 from '../../assets/images/Pinturas/25.jpg';
import P26 from '../../assets/images/Pinturas/26.jpg';
import P27 from '../../assets/images/Pinturas/27.jpg';
import P28 from '../../assets/images/Pinturas/28.jpg';
import P29 from '../../assets/images/Pinturas/29.jpg';
import P30 from '../../assets/images/Pinturas/30.jpg';
import P31 from '../../assets/images/Pinturas/31.jpg';
import P32 from '../../assets/images/Pinturas/32.jpg';
import P33 from '../../assets/images/Pinturas/33.jpg';
import P34 from '../../assets/images/Pinturas/34.jpg';
import P35 from '../../assets/images/Pinturas/35.jpg';
import P36 from '../../assets/images/Pinturas/36.jpg';
import P37 from '../../assets/images/Pinturas/37.jpg';
import P38 from '../../assets/images/Pinturas/38.jpg';
import P39 from '../../assets/images/Pinturas/39.jpg';
import P40 from '../../assets/images/Pinturas/40.jpg';

export default {
    data () {
      return {
        images: [
          P1,P2,P4,P5,P6,P7,P8,P9,P10,
          P11,P12,P13,P14,P15,P16,P17,P18,P19,P20,
          P21,P22,P23,P24,P25,P26,P27,P28,P29,P30,
          P31,P32,P33,P34,P35,P36,P37,P38,P39,P40,P3
        ],
        pageOfItems: [],
        index: null
      }
    },
    methods: {
      onChangePage(pageOfItems) {
        this.pageOfItems = pageOfItems;
      }
    },
    components: {
      VueGallerySlideshow,
      Footer
    } 
}
</script>

<style scoped>
a {
  font-size: 22px !important;
}
li > .active
{
    background-color: #5A4181 !Important;
    border: solid 1px #5A4181;
}
.main__background {
  position: relative;
}
.main__background--img {
  width: 100%;
  height: 570px;
  object-fit: cover;
}
.main__header {
  width: 100%;
  position: absolute;
  color: var(--txt-color);
  text-align: center;
  top: 250px;
  margin: auto;
}
.main__header--title{
  font-family: 'Yeseva One';
  font-size: 70px;
  padding: 9px 0px;
  margin: 2px;
}
.main__header--subtitle{
  font-family: var(--font);
  font-weight: 500;
  padding: 9px 0px;
  line-height: 28px;
  margin: 2px;
}
.main__pagination{
  margin-top: 50px;
  text-align: center;
}
.main__gallery{
  margin-top: -100px;
}
.gallery__content{
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.gallery__content--img{
  width: 500px;
  height: 500px;
  object-fit: none;
  mask-size: 100px;
  margin: 20px;
}

.gallery__content--img img {
  width: 200%;
}
@media (max-width: 800px){
  .main__header--title{
    font-size: 60px;
  }
  .gallery__content--img {
    width: 200px;
    height: 200px;
  }
}
</style>